import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { alpha, Box, Button, Tab, Tabs, CircularProgress, Dialog, DialogActions, DialogContent, Drawer, Typography } from "@mui/material";
import { CustomTabPanel, Table } from "../../components";
import { CreateOrEditQuote } from "./CreateOrEditQuote";
import { ViewQuote } from "./ViewQuote";
import { ActionType, useGetQuotesTableColumns } from "./useGetQuotesTableColumns";
import { UserPermissions } from "../../utils/permissions";
import { useHasPermission } from "../../utils/hooks";
import { CreateOrEditTranslations } from "./CreateOrEditTranslations";
import React from "react";
import { PageTabParams, appendSearchParams } from "../../utils";
import { IQuote, TabKeyEnum } from "../../utils/types";
import { useCreateQuoteMutation, useDeleteQuoteMutation, useEditQuoteMutation, useGetQuotesQuery } from "../../api/apiSlice";
import { MRT_ColumnDef } from "material-react-table";
import toast from "react-hot-toast";
import { CreateQuoteParams, EditQuoteParams } from "../../api/api.types";

type ConfirmDialog = {
  title: "Publish" | "UnPublish" | "Remove" | "Review";
  body: string;
  open: boolean;
};

const defaultSortParams = {
  id: "updatedAt",
  desc: "DESC",
};

// Map key names to the expected API key names
// Other keys not in this map will be sent as is
const mapSortById = {
  origText: "quote",
  origLang: "lang",
  originCulture: "culture",
  reference: "ref",
  origTimePeriod: "time-period",
};

export const Quotes = () => {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [urlParams] = React.useState(new URLSearchParams(location.search));
  const [activeDrawer, setActiveDrawer] = useState<ActionType | null>(null);
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [activeDialogItem, setActiveDialogItem] = useState<IQuote | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filter, setFilter] = React.useState(undefined);
  const [sorting, setSorting] = React.useState([]);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialog>({
    title: "Publish",
    body: "Are you sure you want to publish this?.",
    open: false,
  });

  const [editQuoteMutation, editResult] = useEditQuoteMutation();
  const [createQuoteMutation, createResult] = useCreateQuoteMutation();
  const [deleteQuoteMutation, deleteResult] = useDeleteQuoteMutation();

  const activeTabParam = (urlParams.get("activeTab") ?? PageTabParams[0].value) as TabKeyEnum;
  const activeTabValue = Math.max(
    0,
    PageTabParams.findIndex(({ value }) => value === activeTabParam),
  );

  const apiSupportedParams = PageTabParams[activeTabValue].params;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getSortParams = () => {
    // TODO: Test server side sorting
    const { id, desc } = sorting[0] || defaultSortParams;
    const sortBy = mapSortById[id] || id;
    return { sortBy, sortOrder: desc ? "DESC" : "ASC" } as { sortBy: string; sortOrder: "DESC" | "ASC" | undefined };
  };

  const { data, isLoading, isFetching } = useGetQuotesQuery({
    ...apiSupportedParams,
    text: filter,
    ...getSortParams(),
    page: pagination.pageIndex + 1,
    per_page: pagination.pageSize,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const activeTab = PageTabParams[newValue].value;
    const newUrlParams = appendSearchParams({ activeTab }, urlParams);
    navigate(`${location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
  };

  const quotesData = React.useMemo<MRT_ColumnDef<any>[]>(
    () =>
      data?.data?.map((quote: IQuote) => ({
        ...quote,
        originCulture: quote?.cultures?.[0]?.culture,
        author: quote.authors.map(({ name }) => name).join(", "),
        reference: quote.refs?.[0]?.text,
        source: quote.sources.map(({ name }) => name).join(", "),
      })) ?? [],
    [data?.data],
  );

  const handleCreateOpen = () => {
    setActiveDrawer("Edit");
    setDrawerOpen(true);
    setActiveDialogItem(null);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setActiveDialogItem(null);
  };

  const handleEdit = (item: any) => {
    setActiveDrawer("Edit");
    setDrawerOpen(true);
    setActiveDialogItem(item);
  };

  const handleView = (item: IQuote) => {
    setActiveDrawer("View");
    setDrawerOpen(true);
    setActiveDialogItem(item);
  };

  const handleTableAction = (action: ActionType, item: any) => {
    switch (action) {
      case "View":
        handleView(item);
        break;
      case "Publish":
        handlePublish(item);
        break;
      case "UnPublish":
        handleUnPublish(item);
        break;
      case "Edit":
        handleEdit(item);
        break;
      case "Remove":
        handleRemove(item);
        break;
      case "EditTranslation":
        setActiveDrawer(action);
        setDrawerOpen(true);
        setActiveDialogItem(item);
        break;
    }
  };

  const openConfirmDialog = (content: Omit<ConfirmDialog, "open">) => {
    setConfirmDialog(item => ({ ...item, ...content, open: true }));
  };

  const closeConfirmDialog = () => {
    setConfirmDialog(item => ({ ...item, open: false }));
  };

  const handlePublish = (item: any) => {
    openConfirmDialog({
      title: "Publish",
      body: "Are you sure you want to publish this?",
    });
    setActiveDialogItem(item);
  };

  const handleUnPublish = (item: any) => {
    openConfirmDialog({
      title: "UnPublish",
      body: "Are you sure you want to unpublish this?",
    });
    setActiveDialogItem(item);
  };

  const handleRemove = (item: any) => {
    openConfirmDialog({
      title: "Remove",
      body: "Are you sure you want to Remove this? This action is permanent and cannot be undone.",
    });
    setDrawerOpen(false);
    setActiveDialogItem(item);
  };

  const handleReview = (item: any) => {
    openConfirmDialog({
      title: "Review",
      body: "Are you sure you want to send this for review?",
    });
    setActiveDialogItem(item);
  };

  const handleDialogConfirm = () => {
    const item = activeDialogItem;
    if (!item) {
      return;
    }

    const messages = {
      Publish: {
        value: 1,
        success: "Quote published successfully",
        error: "Failed to publish quote",
      },
      Review: {
        value: -1,
        success: "Quote saved successfully",
        error: "Failed to save quote",
      },
      UnPublish: {
        value: 0,
        success: "Quote unpublished successfully",
        error: "Failed to unpublish quote",
      },
      Remove: {
        value: 0,
        success: "Quote removed successfully",
        error: "Failed to remove quote",
      },
    };

    if (confirmDialog.title === "Remove") {
      deleteQuoteMutation({ id: item.id })
        .unwrap()
        .then(() => {
          closeConfirmDialog();
          toast.success(messages[confirmDialog.title].success);
        })
        .catch(error => {
          toast.error(error?.response?.data || messages[confirmDialog.title].error);
        });
      return;
    }

    const body = {
      id: item.id,
      engText: item.engText,
      origText: item.origText || "",
      origLang: item.origLang || "",
      origTimePeriod: item.origTimePeriod,
      sources: item.sources?.map(({ id }: any) => id),
      cultures: item.cultures?.map(({ id }: any) => id),
      authors: item.authors?.map(({ id }: any) => id),
      tags: item.tags?.map(({ id }: any) => id),
      refs: item.refs?.map(({ id }: any) => id),
      published: messages[confirmDialog.title].value,
    };
    editQuoteMutation(body)
      .unwrap()
      .then(() => {
        closeConfirmDialog();
        handleDrawerClose();
        toast.success(messages[confirmDialog.title].success);
      })
      .catch(error => {
        toast.error(error?.response?.data || messages[confirmDialog.title].error);
      });
  };

  const isEditQuoteParams = (data: CreateQuoteParams | EditQuoteParams): data is EditQuoteParams => {
    return (data as EditQuoteParams).id !== undefined;
  };

  const onSubmit = async (data: CreateQuoteParams | EditQuoteParams) => {
    if (isEditQuoteParams(data)) {
      editQuoteMutation(data)
        .unwrap()
        .then(() => {
          handleDrawerClose();
          toast.success("Changes saved successfully");
        })
        .catch(error => {
          toast.error(error?.data || "Failed to save changes");
        });

      return;
    }

    createQuoteMutation(data)
      .unwrap()
      .then(() => {
        toast.success("Quote created successfully");
        handleDrawerClose();
      })
      .catch(error => {
        toast.error(error?.data || "Failed to create quote");
      });
  };

  const columns = useGetQuotesTableColumns({
    activeTab: activeTabParam,
    setHoveredRow,
    hoveredRow,
    handleTableAction,
  });

  useEffect(() => {
    console.log("QuotesVvv ll React 2 rendered", sorting);
  }, [sorting]);

  const canCreateQuotes = useHasPermission(UserPermissions.CREATE_QUOTES);

  const disableDrawerOutsideClick = ["Edit", "EditTranslation"].includes(activeDrawer || "") ? true : false;

  return (
    <>
      <ConfirmDialogComponent
        content={confirmDialog}
        onConfirm={handleDialogConfirm}
        onClose={closeConfirmDialog}
        loading={editResult.isLoading || deleteResult.isLoading || createResult.isLoading}
      />
      <Drawer
        sx={(theme: any) => ({
          zIndex: theme.zIndex.drawer + 1,
          "& .MuiDrawer-paper": {
            background: alpha(theme.palette.background.default, 1),
          },
        })}
        onClose={handleDrawerClose}
        anchor="right"
        open={drawerOpen}
        ModalProps={{
          hideBackdrop: disableDrawerOutsideClick,
          disableEscapeKeyDown: disableDrawerOutsideClick,
        }}
      >
        {activeDrawer === "Edit" && (
          <CreateOrEditQuote
            onSubmit={onSubmit}
            loading={editResult.isLoading || createResult.isLoading}
            title={activeDialogItem ? "Edit Quote" : "Add Quote"}
            initialValues={activeDialogItem}
            handleClose={handleDrawerClose}
          />
        )}
        {activeDrawer === "View" && (
          <ViewQuote
            activeTab={activeTabParam}
            onEdit={handleEdit}
            onPublish={handlePublish}
            onUnpublish={handleUnPublish}
            onReadyForReview={handleReview}
            loading={editResult.isLoading}
            title="Preview Quote"
            values={activeDialogItem}
            handleClose={handleDrawerClose}
          />
        )}
        {activeDrawer === "EditTranslation" && activeDialogItem && (
          <CreateOrEditTranslations initialValues={activeDialogItem.trans} quoteId={activeDialogItem?.id} handleClose={handleDrawerClose} />
        )}
      </Drawer>

      <Box display="flex" justifyContent="space-between">
        <Tabs value={activeTabValue} onChange={handleTabChange}>
          {PageTabParams.map(value => {
            return (
              <Tab
                key={value.label}
                label={value.label}
                sx={{
                  p: 2,
                  mr: 2,
                }}
              />
            );
          })}
        </Tabs>
        {canCreateQuotes && (
          <Box>
            <Button onClick={handleCreateOpen} sx={{ borderRadius: 0.5 }} color="primary" variant="outlined">
              CREATE QUOTE
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ width: "100%" }}>
        <CustomTabPanel value={activeTabValue} index={activeTabValue}>
          <Table
            columns={columns}
            data={quotesData}
            manualPagination
            onPaginationChange={setPagination}
            manualFiltering
            onGlobalFilterChange={setFilter}
            manualSorting
            onSortingChange={setSorting}
            enableColumnFilters={false}
            state={{ pagination, sorting }}
            rowCount={data?.total}
            loading={isLoading || isFetching}
            muiTableBodyRowProps={({ row }: { row: { id: string; index: number; original: IQuote } }) => ({
              onMouseEnter: () => {
                setHoveredRow(row.index);
              },
              onMouseLeave: () => {
                setHoveredRow(-1);
              },
              onClick: () => {
                handleView(row.original);
              },
            })}
          />
        </CustomTabPanel>
      </Box>
    </>
  );
};

type ConfirmDialogComponentProps = {
  content: ConfirmDialog;
  onConfirm: () => void;
  onClose: () => void;
  loading: boolean;
};

const ConfirmDialogComponent = ({ content, onConfirm, onClose, loading }: ConfirmDialogComponentProps) => {
  const { title, body, open } = content;
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent sx={{ p: 3 }}>
        <Typography sx={{ fontSize: 24, mb: 2 }}>{title}</Typography>
        <Typography sx={{ mb: 2 }}>{body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={(theme: any) => ({ color: theme.palette.text.primary })}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>
          {loading ? <CircularProgress size={16} /> : null}
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
