import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import React, { FC, useCallback, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ConfirmDialogEnum, ConfirmDialogType, User } from "../../utils/types";
import { useRegisterUserMutation, useUpdateUserMutation } from "../../api/apiSlice";
import toast from "react-hot-toast";
import { RoleValue } from "../../api/api.types";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { validatePassword } from "../../utils";
import { VisibilityOff, Visibility } from "@mui/icons-material";

interface CreateOrEditUserProps {
  user?: User | null;
  handleClose: () => void;
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// admin,creator,approver,translator -- Implement api in backend to return the roles.
// Note: Don't include "Mobile User" in the roles as assiging this role is not allowed from the frontend.
const roles: RoleValue[] = ["Admin", "Creator", "Approver", "Translator"];

export const CreateOrEditUser: FC<CreateOrEditUserProps> = props => {
  const { user, handleClose } = props;
  const isEditing = !!user;

  const [userDetails, setUserDetails] = React.useState<Partial<User> | null>(user || null);
  const [initialUserDetails, setInitialUserDetails] = React.useState<Partial<User> | null>(null);
  const defaultRoles = userDetails?.roles?.map(role => role.role);
  const [selectedRoles, setSelectedRoles] = React.useState<string[]>(defaultRoles || []);
  const [password, setPassword] = React.useState<string>("");
  const [openedConfirmDialog, setOpenedConfirmDialog] = React.useState<ConfirmDialogType | undefined>();
  const [passwordError, setPasswordError] = React.useState<string | null>(null);
  const [roleError, setRoleError] = React.useState<string | null>(null);
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [nameError, setNameError] = React.useState<string | null>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [disableSaveButton, setDisableSaveButton] = React.useState<boolean>(true);
  const [isCustomCreateLoading, setIsCustomCreateLoading] = React.useState<boolean>(false);

  const handleOnChangeRoles = (_event: React.SyntheticEvent<Element, Event>, values: any) => {
    setSelectedRoles(values);

    if (values.length === 0) {
      setRoleError("At least one role should be selected");
    } else {
      setRoleError(null);
    }
  };

  const handleOnPasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
    const { isValid, message } = validatePassword(event.target.value);
    if (!isValid && event.target.value) {
      setPasswordError(message);
    } else {
      setPasswordError(null);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserDetails({ ...userDetails, [event.target.id]: event.target.value });
    if (event.target.id === "email") {
      if (!emailRegex.test(event.target.value)) {
        setEmailError("Invalid email address");
      } else {
        setEmailError(null);
      }
    }

    if (event.target.id === "name") {
      if (!event.target.value) {
        setNameError("Name is required");
      } else {
        setNameError(null);
      }
    }
  };

  const [updateUser, updateUserResult] = useUpdateUserMutation();
  const [registerUser] = useRegisterUserMutation();
  const { isSuccess: isEditSuccess, isLoading: isUpdatingLoading } = updateUserResult;
  const isLoading = isEditing ? isUpdatingLoading : isCustomCreateLoading;

  const handleUserCreation = async () => {
    try {
      setIsCustomCreateLoading(true);

      const data = await registerUser({
        name: userDetails?.name || "",
        email: userDetails?.email || "",
        password: password,
      }).unwrap();

      await updateUser({
        id: data.id,
        roles: selectedRoles as RoleValue[],
      }).unwrap();

      toast.success("User has been successfully created");
    } catch (error) {
      const errorResponse = error as { data: string };
      toast.error(errorResponse?.data || "Failed to create user");
    } finally {
      setIsCustomCreateLoading(false);
    }
  };

  const handleSubmit = () => {
    if (userDetails?.id && isEditing) {
      updateUser({
        id: userDetails?.id,
        password: password,
        roles: selectedRoles as RoleValue[],
      })
        .unwrap()
        .then(() => {
          toast.success("User has been successfully updated");
        })
        .catch(error => {
          toast.error(error?.data || "Failed to update user");
        });
      return;
    }

    if (!isEditing) {
      handleUserCreation();
    }
  };

  const onClickSubmitButton = () => {
    if (isEditing) {
      setOpenedConfirmDialog(ConfirmDialogEnum.Save);
      return;
    }
    handleSubmit();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const hasChanges = useCallback(() => {
    const userRoles = userDetails?.roles?.map(role => role.role) || [];
    const rolesChanged = userRoles.sort().join(",") !== selectedRoles.sort().join(",");

    return (
      rolesChanged ||
      Object.entries(userDetails || {}).some(([key, value]) => {
        if (key === "roles") return false; // Skip roles comparison here
        const typedKey = key as keyof Partial<User>;
        return value !== initialUserDetails?.[typedKey];
      }) ||
      password !== ""
    );
  }, [initialUserDetails, password, selectedRoles, userDetails]);

  useEffect(() => {
    // If Creating, password is required but if editing, reset password is optional
    const hasPassword = isEditing ? true : !!password;
    const hasError = !!emailError || !!nameError || !!passwordError || !!roleError;
    const hasRequiredFields = !!selectedRoles.length && !!userDetails?.email && !!userDetails?.name && hasPassword;
    const shouldDisable = !hasChanges() || isLoading || hasError || !hasRequiredFields;
    setDisableSaveButton(shouldDisable);
  }, [
    emailError,
    hasChanges,
    isEditing,
    isLoading,
    nameError,
    password,
    passwordError,
    roleError,
    selectedRoles.length,
    userDetails?.email,
    userDetails?.name,
  ]);

  useEffect(() => {
    // Close the dialog upon successful edit only, as we are updating the user to add roles after creation.
    if (isEditSuccess) {
      handleClose();
    }
  }, [handleClose, isEditSuccess]);

  // Set initial user details on component mount
  useEffect(() => {
    if (user) {
      setInitialUserDetails(user);
    }
  }, [user]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      {openedConfirmDialog === ConfirmDialogEnum.Save && (
        <ConfirmDialog
          open={true}
          title="Confrim"
          body={`Are you sure you want to update this User: ${userDetails?.name}?`}
          onClose={() => setOpenedConfirmDialog(undefined)}
          onConfirm={() => handleSubmit()}
          loading={isUpdatingLoading}
        />
      )}
      <DialogTitle>{isEditing ? `Edit User ${user.name}` : "Create User"}</DialogTitle>
      <DialogContent>
        <Box gap={1} display="flex" flexDirection="column">
          <TextField
            id="name"
            label="Name"
            value={userDetails?.name || ""}
            onChange={handleOnChange}
            onBlur={handleOnChange}
            error={!!nameError}
            helperText={nameError}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            id="email"
            label="Email"
            value={userDetails?.email || ""}
            onChange={handleOnChange}
            onBlur={handleOnChange}
            error={!!emailError}
            helperText={emailError}
            fullWidth
            required
            margin="normal"
          />

          <Autocomplete
            multiple
            disableCloseOnSelect
            disableClearable
            id="roles-selector"
            options={roles}
            onChange={handleOnChangeRoles}
            defaultValue={defaultRoles}
            isOptionEqualToValue={(option, value) => option?.toLowerCase() === value?.toLowerCase()}
            getOptionDisabled={option => option === "Mobile User"}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip key={key} label={option} {...tagProps} disabled={option === "Mobile User"} />;
              })
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option?.toUpperCase()}
              </li>
            )}
            renderInput={params => (
              <TextField required error={!!roleError} helperText={roleError} variant="standard" {...params} label="User roles" />
            )}
          />
          <TextField
            label={isEditing ? "Reset Password" : "Password"}
            id="password"
            value={password}
            error={!!passwordError}
            helperText={passwordError}
            onChange={handleOnPasswordChange}
            onBlur={handleOnPasswordChange}
            fullWidth
            margin="normal"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword()}
                    onMouseDown={event => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isCustomCreateLoading}
          disableElevation
          variant="outlined"
          sx={{ width: 100, borderRadius: "20px" }}
          onClick={handleClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          onClick={onClickSubmitButton}
          disabled={disableSaveButton}
          sx={theme => ({ width: 100, borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
          disableElevation
          color="primary"
          variant="contained"
          type="button"
        >
          {isCustomCreateLoading && <CircularProgress size={16} />}
          Save
        </Button>
      </DialogActions>
    </Box>
  );
};
