import { Box, Button, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { useDeleteTagMutation, useEditTagMutation, useGetTagsQuery } from "../../api/apiSlice";
import { useGetTagsTableColumns } from "./useGetTagsTableColumns";
import { Table, CustomTabPanel, ConfirmDialog } from "../../components";
import { type MRT_ColumnDef } from "material-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { appendSearchParams, PageTabParams } from "../../utils";
import { ViewTag } from "./ViewTag";
import { TabLabelEnum, TabKeyEnum, PreviewTag, ModalType, ModalEnum } from "../../utils/types";
import { Drawer } from "../../components/Drawer/Drawer";
import { CreateOrEditTag } from "./CreateOrEditTag";
import toast from "react-hot-toast";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";

export const Tags = () => {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [urlParams] = React.useState(new URLSearchParams(location.search));
  const [selectedTag, setSelectedTag] = React.useState<PreviewTag | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<ModalType | undefined>();
  const [hoveredRow, setHoveredRow] = React.useState(-1);
  const [isUnpublishDialogOpen, setIsUnpublishDialogOpen] = React.useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
  const [isPublishDialogOpen, setIsPublishDialogOpen] = React.useState(false);
  const [isReviewDialogOpen, setIsReviewDialogOpen] = React.useState(false);
  const [filter, setFilter] = React.useState<string | undefined>(undefined);

  const [editTag, editTagResult] = useEditTagMutation();
  const [deleteTag, deleteTagResult] = useDeleteTagMutation();

  const activeTabParam = (urlParams.get("activeTab") ?? PageTabParams[0].value) as TabKeyEnum;
  const activeTabValue = Math.max(
    0,
    PageTabParams.findIndex(({ value }) => value === activeTabParam),
  );

  const apiSupportedParams = PageTabParams[activeTabValue].params;
  const { data, isLoading, isFetching } = useGetTagsQuery({
    ...apiSupportedParams,
    tag: filter || undefined,
    page: pagination.pageIndex + 1,
    per_page: pagination.pageSize,
  });

  const tagsState = React.useMemo(() => data?.data || [], [data]);
  const tagsData = React.useMemo<MRT_ColumnDef<PreviewTag>[]>(
    () =>
      tagsState?.map((item: any) => ({
        ...item,
        createdBy: item.createdBy?.name,
        updatedBy: item.updatedBy?.name,
        published:
          item.published === 0 ? TabLabelEnum.Unpublished : item.published === -1 ? TabLabelEnum.ReadyForReview : TabLabelEnum.Published,
      })),
    [tagsState],
  );

  const total = data?.total || 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const activeTab = PageTabParams[newValue].value;
    const newUrlParams = appendSearchParams({ activeTab }, urlParams);
    navigate(`${location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
  };

  const getDrawerTitle = () => {
    if (isDrawerOpen === ModalEnum.Preview) {
      return "Preview Tag";
    }
    if (isDrawerOpen === ModalEnum.Create) {
      return "Create Tag";
    }
    if (isDrawerOpen === ModalEnum.Edit) {
      return "Edit Tag";
    }
    return "";
  };

  const handlePreviewTag = () => {
    setIsDrawerOpen(ModalEnum.Preview);
  };

  const handleCreateTag = () => {
    setHoveredRow(-1);
    setSelectedTag(null);
    setIsDrawerOpen(ModalEnum.Create);
  };

  const handleEditTag = () => {
    setIsDrawerOpen(ModalEnum.Edit);
  };

  const handleOpenRemoveConfirmDialog = () => {
    setIsRemoveDialogOpen(true);
  };
  const handleRemoveTag = () => {
    if (selectedTag?.id) {
      deleteTag({ id: selectedTag.id })
        .unwrap()
        .then(() => {
          setIsRemoveDialogOpen(false);
          toast.success("Tag removed successfully");
        })
        .catch(error => {
          toast.error(error?.response?.data || "Failed to remove tag");
        });
    } else {
      console.error("No tag selected");
    }
  };

  const handleToggleUnpublishConfirmDialog = (open: boolean) => {
    setIsUnpublishDialogOpen(open);
  };

  const handleTogglePublishConfrimDialog = (open: boolean) => {
    setIsPublishDialogOpen(open);
  };

  const handleToggleReviewConfrimDialog = (open: boolean) => {
    setIsReviewDialogOpen(open);
  };

  const handlePublishChange = (published: TabKeyEnum) => {
    const messages = {
      [TabKeyEnum.Published]: {
        value: 1,
        success: "Tag published successfully",
        error: "Failed to publish tag",
      },
      [TabKeyEnum.ReadyForReview]: {
        value: -1,
        success: "Tag saved successfully",
        error: "Failed to saved tag",
      },
      [TabKeyEnum.Unpublished]: {
        value: 0,
        success: "Tag unpublish successfully",
        error: "Failed to unpublish tag",
      },
    };

    if (selectedTag?.id) {
      editTag({
        id: selectedTag?.id,
        published: messages[published].value,
        tag: selectedTag?.tag,
        type: selectedTag?.type,
      })
        .unwrap()
        .then(() => {
          handleToggleUnpublishConfirmDialog(false);
          handleTogglePublishConfrimDialog(false);
          handleToggleReviewConfrimDialog(false);
          handleDrawerClose();
          toast.success(messages[published].success);
        })
        .catch(error => {
          toast.error(error?.data || messages[published].error);
        });
    } else {
      console.error("No tag selected");
    }
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(undefined);
  };

  const columns = useGetTagsTableColumns({
    activeTab: activeTabParam,
    handlePreviewTag,
    handleEditTag,
    setHoveredRow,
    hoveredRow,
    handleRemoveTag: handleOpenRemoveConfirmDialog,
    handleUnpublishTag: () => handleToggleUnpublishConfirmDialog(true),
    handlePublishTag: () => handleTogglePublishConfrimDialog(true),
  });

  const canCreateTag = useHasPermission(UserPermissions.CREATE_TAGS);

  return (
    <Box>
      <Drawer title={getDrawerTitle()} onClose={handleDrawerClose} open={!!isDrawerOpen} sx={{ width: "500px", height: "100%" }}>
        {isDrawerOpen === ModalEnum.Preview && (
          <ViewTag
            activeTab={activeTabParam}
            handleClose={handleDrawerClose}
            handleEditTag={handleEditTag}
            tag={selectedTag}
            handlePublishTag={() => handleTogglePublishConfrimDialog(true)}
            handleUnpublishTag={() => handleToggleUnpublishConfirmDialog(true)}
            handleReviewTag={() => handleToggleReviewConfrimDialog(true)}
          />
        )}
        {(isDrawerOpen === ModalEnum.Create || isDrawerOpen === ModalEnum.Edit) && (
          <CreateOrEditTag handleClose={handleDrawerClose} tag={selectedTag} isEditing={isDrawerOpen === ModalEnum.Edit} />
        )}
      </Drawer>

      <ConfirmDialog
        open={isUnpublishDialogOpen}
        title={`Unpublish Tag: ${selectedTag?.tag}`}
        body={`Are you sure you want to unpublish this tag?`}
        onClose={() => handleToggleUnpublishConfirmDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Unpublished)}
        loading={editTagResult.isLoading}
      />

      <ConfirmDialog
        open={isRemoveDialogOpen}
        title={`Remove Tag: ${selectedTag?.tag}`}
        body={`Are you sure you want to remove this tag? This action is permanent and cannot be undone.`}
        onClose={() => setIsRemoveDialogOpen(false)}
        onConfirm={handleRemoveTag}
        loading={deleteTagResult.isLoading}
      />

      <ConfirmDialog
        open={isPublishDialogOpen}
        title={`Publish Tag: ${selectedTag?.tag}`}
        body={`Are you sure you want to publish this tag?`}
        onClose={() => handleTogglePublishConfrimDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Published)}
        loading={editTagResult.isLoading}
      />

      <ConfirmDialog
        open={isReviewDialogOpen}
        title={`Save Tag: ${selectedTag?.tag}`}
        body={`Are you sure you want to save this tag?`}
        onClose={() => handleToggleReviewConfrimDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.ReadyForReview)}
        loading={editTagResult.isLoading}
      />
      <Box display="flex" justifyContent="space-between">
        <Tabs value={activeTabValue} onChange={handleChange}>
          {PageTabParams.map((value, index: number) => {
            return (
              <Tab
                key={value.label}
                label={value.label}
                sx={{
                  p: 2,
                  mr: 2,
                }}
              />
            );
          })}
        </Tabs>
        {canCreateTag && (
          <Box>
            <Button onClick={handleCreateTag} sx={{ borderRadius: 0.5 }} color="primary" variant="outlined">
              CREATE TAG
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ width: "100%" }}>
        <CustomTabPanel value={activeTabValue} index={activeTabValue}>
          <Table
            columns={columns}
            data={tagsData}
            manualPagination
            manualFiltering
            onGlobalFilterChange={setFilter}
            onPaginationChange={setPagination}
            state={{ pagination }}
            rowCount={total}
            loading={isLoading || isFetching}
            muiTableBodyRowProps={({ row }: { row: { id: string; index: number; original: PreviewTag } }) => ({
              onMouseEnter: () => {
                setHoveredRow(row.index);
                setSelectedTag(row.original);
              },
              onClick: () => {
                handlePreviewTag();
              },
            })}
          />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};
