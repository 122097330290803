import { Chip, MenuItem, Stack } from "@mui/material";
import { ActionCell } from "../../components/ActionCell/ActionCell";
import { OneLiner } from "../../components/Table/Table";
import { userFriendlyDateTimestamp } from "../../utils";
import { TabKeyEnum, TableMenuItemInterface } from "../../utils/types";
import { MouseEvent, useCallback, useState } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { UserPermissions } from "../../utils/permissions";
import { useHasPermission } from "../../utils/hooks";

interface Params {
  activeTab: TabKeyEnum;
  setHoveredRow: (rowIndex: number) => void;
  hoveredRow: number;
  handleEditSource: () => void;
  handleUnpublishSource: () => void;
  handlePublishSource: () => void;
  handleReviewSource: () => void;
  handlePreviewSource: () => void;
  handleRemoveSource: () => void;
}

export const useGetSourcesTableColumns = (params: Params) => {
  const {
    setHoveredRow,
    hoveredRow,
    activeTab,
    handlePreviewSource,
    handleEditSource,
    handleUnpublishSource,
    handlePublishSource,
    handleRemoveSource,
  } = params;

  const [shouldCloseMenuSelection, setShouldCloseMenuSelection] = useState<boolean>(false);

  const handlePreviewSourceSelection = useCallback(() => {
    setShouldCloseMenuSelection(true);
    handlePreviewSource();
  }, [handlePreviewSource, setShouldCloseMenuSelection]);

  const handleEditSelectedSource = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleEditSource();
    },
    [handleEditSource, setShouldCloseMenuSelection],
  );

  const handleUnpublishSelectedSource = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleUnpublishSource();
    },
    [handleUnpublishSource, setShouldCloseMenuSelection],
  );

  const handlePublishSelectedSource = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handlePublishSource();
    },
    [handlePublishSource, setShouldCloseMenuSelection],
  );

  const handleRemoveSelectedSource = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleRemoveSource();
    },
    [handleRemoveSource, setShouldCloseMenuSelection],
  );

  const canEditReadyAndUnPublishedSources = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_SOURCES);
  const canApprovePublishSources = useHasPermission(UserPermissions.APPROVE_PUBLISH_SOURCES);
  const canRemoveSources = useHasPermission(UserPermissions.REMOVE_SOURCES);
  const canUnpublishSources = useHasPermission(UserPermissions.UNPUBLISH_SOURCES);

  const menuItems: TableMenuItemInterface[] = [
    {
      label: "Approve & Publish",
      onClick: handlePublishSelectedSource,
      visible: activeTab === TabKeyEnum.ReadyForReview && canApprovePublishSources,
    },
    {
      label: "Preview",
      onClick: handlePreviewSourceSelection,
      visible: true,
    },
    {
      label: "Edit",
      onClick: handleEditSelectedSource,
      visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedSources,
    },
    {
      label: "Unpublish",
      onClick: handleUnpublishSelectedSource,
      visible: [TabKeyEnum.Published, TabKeyEnum.ReadyForReview].includes(activeTab) && canUnpublishSources,
    },
    {
      label: "Remove",
      onClick: handleRemoveSelectedSource,
      visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canRemoveSources,
      sx: { color: "red" },
    },
  ];

  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "cover",
      header: "Cover",
      size: 130,
      Cell: ({ row }: any) =>
        row.original.cover ? (
          <img alt="cover" height={30} src={row.original.cover ? `/api${row.original.cover}` : ""} loading="lazy" />
        ) : null,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 300,
      Cell: ({ row }) => <OneLiner text={row.original.name} />,
    },
    {
      accessorKey: "authors",
      header: "Authors",
      size: 300,
      Cell: ({ row }) => (
        <Stack direction="row" spacing={1}>
          {row.original.authors?.map(({ name, id }: any) => {
            return <Chip key={id} label={name} />;
          })}
        </Stack>
      ),
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      Cell: ({ row }) => userFriendlyDateTimestamp(row.original.createdAt),
    },
    {
      accessorKey: "createdBy",
      header: "Created By",
      Cell: ({ row }) => <OneLiner text={row.original.createdBy?.name} />,
    },
    {
      accessorKey: "updatedAt",
      header: "Updated At",
      Cell: ({ row }) => userFriendlyDateTimestamp(row.original.updatedAt),
    },
    {
      accessorKey: "updatedBy",
      header: "Updated By",
      Cell: ({ row }) => <OneLiner text={row.original.updatedBy?.name} />,
    },
    {
      accessorKey: "action",
      id: "action",
      size: 50,
      header: "",
      enableResizing: false,
      muiTableHeadCellProps: () => ({
        sx: {
          visibility: "hidden",
        },
      }),
      Cell: ({ row }: any) => {
        if (hoveredRow !== row.index) return null;
        return (
          <ActionCell
            shouldCloseMenuSelection={shouldCloseMenuSelection}
            setShouldCloseMenuSelection={setShouldCloseMenuSelection}
            hovered={hoveredRow === row.index}
            loading={false}
            activeTab={activeTab}
            onActionButtonClick={(event: MouseEvent<HTMLElement>) => {
              event?.stopPropagation?.();
              setHoveredRow(row.index);
            }}
          >
            {menuItems.map(
              (item, index) =>
                item.visible && (
                  <MenuItem key={index} sx={item.sx} onClick={item.onClick}>
                    {item.label}
                  </MenuItem>
                ),
            )}
          </ActionCell>
        );
      },
    },
  ];

  return columns;
};
