import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useLogin } from "./loginController";
import { useGetIpJsonQuery } from "../../api/thirdPartyApiSlice";
import { useInvalidateAllProvideraTagsMutation } from "../../api/apiSlice";

const Login: React.FC = () => {
  const { formData, handleChange, handleSubmit, errors, loading } = useLogin();

  const { data: ipInfo } = useGetIpJsonQuery({});

  // Invalidate cache on login
  // TODO: This should be moved to a global context
  const [invalidateAllProvideraTags] = useInvalidateAllProvideraTagsMutation();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    invalidateAllProvideraTags();
    handleSubmit(ipInfo || {});
  };

  return (
    <Box sx={{ pb: 4 }}>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Login
      </Typography>
      <form onSubmit={onSubmit}>
        <TextField
          label="Email"
          variant="standard"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          variant="standard"
          name="password"
          error={!!errors.password}
          helperText={errors.password}
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button
          sx={{ mt: 8, width: "100%", textTransform: "none" }}
          disableElevation
          disabled={loading}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
        >
          {loading ? <CircularProgress size={30} /> : `Sign In`}
        </Button>
      </form>
    </Box>
  );
};

export default Login;
