import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dialog } from "@mui/material";
import { Drawer } from "../../components/Drawer/Drawer";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ConfirmDialog, CustomTabPanel, Table } from "../../components";
import { appendSearchParams } from "../../utils";
import { useGetUsersTableColumns } from "./useGetUsersTableColumns";
import { useGetUsersQuery, useUpdateUserMutation } from "../../api/apiSlice";
import toast from "react-hot-toast";
import { UserTabParam, UserLabel, UserTabEnum, UserStatus } from "./types";
import { CreateOrEditUser } from "./CreateOrEditUser";
import { ModalEnum, ModalType, User } from "../../utils/types";
import { ViewUser } from "./ViewUser";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";

enum ConfirmDialogEnum {
  Activate = "Activate",
  InActivate = "InActivate",
}

type ConfirmDialogType = keyof typeof ConfirmDialogEnum;

export const userTabParams: UserTabParam[] = [
  {
    label: UserLabel.WebOnly,
    value: UserTabEnum.WebOnly,
    params: {
      status: UserStatus.Active,
      roles: ["Admin", "Creator", "Approver", "Translator"],
    },
  },
  {
    label: UserLabel.AllActive,
    value: UserTabEnum.AllActive,
    params: {
      status: UserStatus.Active,
    },
  },
  {
    label: UserLabel.MobileUsers,
    value: UserTabEnum.MobileUsers,
    params: {
      status: UserStatus.Active,
      roles: ["Mobile User"],
    },
  },
  {
    label: UserLabel.Inactive,
    value: UserTabEnum.InActive,
    params: {
      status: UserStatus.Inactive,
    },
  },
];

export const Users = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [urlParams] = useState(new URLSearchParams(location.search));
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [openedConfirmDialog, setOpenedConfirmDialog] = React.useState<ConfirmDialogType | undefined>();
  const [openUserDialog, setOpenUserDialog] = useState<ModalType | undefined>();
  const [activeDrawer, setActiveDrawer] = useState<ModalType | undefined>();
  const [filter, setFilter] = useState<string | undefined>();

  const [updateUser, updateUserResult] = useUpdateUserMutation();

  const activeTabParam = (urlParams.get("activeTab") ?? userTabParams[0].value) as UserTabEnum;
  const activeTab = Math.max(
    0,
    userTabParams.findIndex(({ value }) => value === activeTabParam),
  );

  const apiSupportedParams = userTabParams[activeTab].params;

  const {
    data: userState,
    isLoading: loading,
    isFetching,
  } = useGetUsersQuery({
    ...apiSupportedParams,
    nameOrEmail: filter,
    per_page: pagination.pageSize,
    page: pagination.pageIndex + 1,
  });

  const usersData = useMemo(() => {
    const { data } = userState || { data: [] };
    return data;
  }, [userState]);

  const total = userState?.total || 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const activeTab = userTabParams[newValue].value;
    const newUrlParams = appendSearchParams({ activeTab }, urlParams);
    navigate(`${location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
  };

  const handleOpenEditUserDialog = () => {
    setOpenUserDialog(ModalEnum.Edit);
  };

  const handleOpenCreateUserDialog = () => {
    setSelectedUser(null);
    setHoveredRow(-1);
    setOpenUserDialog(ModalEnum.Create);
  };

  const handleCloseEditUserDialog = () => {
    setOpenUserDialog(undefined);
    setSelectedUser(null);
  };

  const handleToggleConfirmDialog = (dialog: ConfirmDialogType) => {
    setOpenedConfirmDialog(dialog);
  };

  const handleConfirmDialogClose = () => {
    setOpenedConfirmDialog(undefined);
  };

  useEffect(() => {
    if (updateUserResult.isSuccess) {
      handleCloseEditUserDialog();
    }
  }, [updateUserResult.isSuccess]);

  const handleStatusChange = (status: ConfirmDialogEnum) => {
    const messages = {
      [ConfirmDialogEnum.Activate]: {
        value: UserStatus.Active,
        success: "User activated successfully",
        error: "Failed to activate user",
      },
      [ConfirmDialogEnum.InActivate]: {
        value: UserStatus.Inactive,
        success: "User inactivated successfully",
        error: "Failed to inactivate user",
      },
    };

    if (selectedUser) {
      updateUser({
        id: selectedUser.id,
        status: messages[status].value,
      })
        .unwrap()
        .then(() => {
          handleConfirmDialogClose();
          toast.success(messages[status].success);
        })
        .catch(error => {
          toast.error(error?.data || messages[status].error);
        });
    } else {
      console.error("No user selected");
    }
  };

  const handlePreviewUser = () => {
    setActiveDrawer(ModalEnum.Preview);
  };

  const handleDrawerClose = () => {
    setActiveDrawer(undefined);
  };

  const columns = useGetUsersTableColumns({
    setHoveredRow,
    hoveredRow,
    onClickActivate: () => handleToggleConfirmDialog(ConfirmDialogEnum.Activate),
    onClickInactivate: () => handleToggleConfirmDialog(ConfirmDialogEnum.InActivate),
    onClickEditUser: handleOpenEditUserDialog,
    onClickPreview: handlePreviewUser,
    activeTab: activeTabParam,
  });

  const canCreateUsers = useHasPermission(UserPermissions.CREATE_USERS);

  return (
    <>
      <Dialog open={openUserDialog !== undefined} fullWidth maxWidth="sm">
        <CreateOrEditUser handleClose={handleCloseEditUserDialog} user={selectedUser} />
      </Dialog>

      <Drawer title={`${activeDrawer} user`} onClose={handleDrawerClose} open={!!activeDrawer} sx={{ width: "500px", height: "100%" }}>
        {activeDrawer === ModalEnum.Preview && <ViewUser activeTab={activeTabParam} handleClose={handleDrawerClose} user={selectedUser} />}
      </Drawer>

      {openedConfirmDialog === ConfirmDialogEnum.Activate && (
        <ConfirmDialog
          open={true}
          title={`Activate ${selectedUser?.name}`}
          body="Are you sure you want to activate this user?"
          onClose={handleConfirmDialogClose}
          onConfirm={() => handleStatusChange(ConfirmDialogEnum.Activate)}
          loading={updateUserResult.isLoading}
        />
      )}

      {openedConfirmDialog === ConfirmDialogEnum.InActivate && (
        <ConfirmDialog
          open={true}
          title={`InActivate ${selectedUser?.name}`}
          body="Are you sure you want to inactivate this user?"
          onClose={handleConfirmDialogClose}
          onConfirm={() => handleStatusChange(ConfirmDialogEnum.InActivate)}
          loading={updateUserResult.isLoading}
        />
      )}

      <Box sx={{ width: "100%" }}>
        <Box display="flex" justifyContent="space-between">
          <Tabs value={activeTab} onChange={handleChange}>
            {userTabParams.map((value, index: number) => {
              return (
                <Tab
                  key={value.label}
                  label={value.label}
                  sx={{
                    p: 2,
                    mr: 2,
                  }}
                />
              );
            })}
          </Tabs>
          {canCreateUsers && (
            <Box>
              <Button onClick={handleOpenCreateUserDialog} sx={{ borderRadius: 0.5 }} color="primary" variant="outlined">
                CREATE USER
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          <CustomTabPanel value={activeTab} index={activeTab}>
            <Table
              columns={columns}
              data={usersData}
              manualPagination
              onPaginationChange={setPagination}
              manualFiltering
              onGlobalFilterChange={setFilter}
              state={{ pagination, columnPinning: { right: ["action"] } }}
              rowCount={total}
              loading={loading || isFetching}
              muiTableBodyRowProps={({ row }: { row: { id: string; index: number; original: User } }) => ({
                onClick: () => {
                  handlePreviewUser();
                },
                onMouseEnter: () => {
                  setHoveredRow(row.index);
                  setSelectedUser(row.original);
                },
                onMouseLeave: () => {
                  setHoveredRow(-1);
                },
              })}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};
