import { useState, useRef } from "react";
import { Box, Button, Tabs, Tab } from "@mui/material";
import { ConfirmDialog, CustomTabPanel, Table } from "../../components";
import { appendSearchParams, PageTabParams } from "../../utils";
import { IFileUploadHandle, IPreviewCulture, ModalType, TabKeyEnum } from "../../utils/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteCultureMutation, useEditCultureMutation, useGetCulturesQuery } from "../../api/apiSlice";
import React from "react";
import { useGetCulturesTableColumns } from "./useGetCulturesTableColumns";
import toast from "react-hot-toast";
import { CreateOrEditCulture } from "./CreateOrEditCulture";
import { Drawer } from "../../components/Drawer/Drawer";
import { ViewCulture } from "./ViewCulture";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";

export const Cultures = () => {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [urlParams] = React.useState(new URLSearchParams(location.search));
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [activeDrawer, setActiveDrawer] = useState<ModalType>("Create");
  const [activeCulture, setActiveCulture] = useState<IPreviewCulture | null>(null);
  const [isUnpublishDialogOpen, setIsUnpublishDialogOpen] = React.useState(false);
  const [isPublishDialogOpen, setIsPublishDialogOpen] = React.useState(false);
  const [isReviewDialogOpen, setIsReviewDialogOpen] = React.useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filter, setFilter] = useState<string | undefined>(undefined);

  const [editCulture, editResult] = useEditCultureMutation();
  const [deleteCulture, deleteResult] = useDeleteCultureMutation();

  const fileUploadRef = useRef<IFileUploadHandle>(null);

  const activeTabParam = (urlParams.get("activeTab") ?? PageTabParams[0].value) as TabKeyEnum;
  const activeTabValue = Math.max(
    0,
    PageTabParams.findIndex(({ value }) => value === activeTabParam),
  );

  const apiSupportedParams = PageTabParams[activeTabValue].params;
  const { data, isLoading, isFetching } = useGetCulturesQuery({
    ...apiSupportedParams,
    culture: filter || undefined,
    page: pagination.pageIndex + 1,
    per_page: pagination.pageSize,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const activeTab = PageTabParams[newValue].value;
    const newUrlParams = appendSearchParams({ activeTab }, urlParams);
    navigate(`${location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
  };

  const handleEditCulture = () => {
    setActiveDrawer("Edit");
    setIsDrawerOpen(true);
  };

  const openCreateCultureDialog = () => {
    setHoveredRow(-1);
    setActiveCulture(null);
    setActiveDrawer("Create");
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setActiveCulture(null);
  };

  const handlePreviewCulture = () => {
    setActiveDrawer("Preview");
    setIsDrawerOpen(true);
  };

  const columns = useGetCulturesTableColumns({
    activeTab: activeTabParam,
    setHoveredRow,
    hoveredRow,
    handlePreviewCulture,
    handleEditCulture,
    handleUnpublishCulture: () => handleToggleUnpublishConfirmDialog(true),
    handlePublishCulture: () => handleTogglePublishConfrimDialog(true),
    handleReviewCulture: () => handleToggleReviewConfrimDialog(true),
    handleRemoveCulture: () => handleToggleRemoveConfirmDialog(true),
  });

  const handleToggleUnpublishConfirmDialog = (open: boolean) => {
    setIsUnpublishDialogOpen(open);
  };

  const handleTogglePublishConfrimDialog = (open: boolean) => {
    setIsPublishDialogOpen(open);
  };

  const handleToggleReviewConfrimDialog = (open: boolean) => {
    setIsReviewDialogOpen(open);
  };

  const handleToggleRemoveConfirmDialog = (open: boolean) => {
    setIsRemoveDialogOpen(open);
  };

  const handleRemoveCulture = () => {
    if (activeCulture?.id) {
      deleteCulture({ id: activeCulture.id })
        .unwrap()
        .then(() => {
          setIsRemoveDialogOpen(false);
          toast.success("Culture removed successfully");
        })
        .catch(error => {
          toast.error(error?.response?.data || "Failed to remove culture");
        });
    } else {
      console.error("No culture selected");
    }
  };

  const handlePublishChange = (published: TabKeyEnum) => {
    const messages = {
      [TabKeyEnum.Published]: {
        value: 1,
        success: "Culture published successfully",
        error: "Failed to publish culture",
      },
      [TabKeyEnum.ReadyForReview]: {
        value: -1,
        success: "Culture saved successfully",
        error: "Failed to saved culture",
      },
      [TabKeyEnum.Unpublished]: {
        value: 0,
        success: "Culture unpublish successfully",
        error: "Failed to unpublish culture",
      },
    };

    if (activeCulture) {
      editCulture({
        id: activeCulture.id,
        published: messages[published].value,
        culture: activeCulture.culture,
        icon: activeCulture.icon,
      })
        .unwrap()
        .then(() => {
          handleToggleUnpublishConfirmDialog(false);
          handleTogglePublishConfrimDialog(false);
          handleToggleReviewConfrimDialog(false);
          handleDrawerClose();
          toast.success(messages[published].success);
        })
        .catch(error => {
          toast.error(error?.response?.data || messages[published].error);
        });
    } else {
      console.error("No culture selected");
    }
  };

  const canCreateCulture = useHasPermission(UserPermissions.CREATE_CULTURES);

  return (
    <>
      <Drawer title={`${activeDrawer} Culture`} onClose={handleDrawerClose} open={isDrawerOpen} sx={{ width: "500px", height: "100%" }}>
        {isDrawerOpen && (activeDrawer === "Create" || activeDrawer === "Edit") && (
          <CreateOrEditCulture
            isModalOpen={true}
            handleClose={handleDrawerClose}
            modalType={activeDrawer}
            culture={activeCulture}
            fileUploadRef={fileUploadRef}
          />
        )}

        {isDrawerOpen && activeDrawer === "Preview" && (
          <ViewCulture
            activeTab={activeTabParam}
            handleClose={handleDrawerClose}
            handleEditCulture={handleEditCulture}
            handleReviewCulture={() => handleToggleReviewConfrimDialog(true)}
            culture={activeCulture}
            handlePublishCulture={() => handleTogglePublishConfrimDialog(true)}
            handleUnpublishCulture={() => handleToggleUnpublishConfirmDialog(true)}
          />
        )}
      </Drawer>
      <ConfirmDialog
        open={isUnpublishDialogOpen}
        title={`Unpublish Culture: ${activeCulture?.culture}`}
        body={`Are you sure you want to unpublish this culture?`}
        onClose={() => handleToggleUnpublishConfirmDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Unpublished)}
        loading={editResult.isLoading}
      />

      <ConfirmDialog
        open={isPublishDialogOpen}
        title={`Publish Culture: ${activeCulture?.culture}`}
        body={`Are you sure you want to publish this culture?`}
        onClose={() => handleTogglePublishConfrimDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Published)}
        loading={editResult.isLoading}
      />

      <ConfirmDialog
        open={isReviewDialogOpen}
        title={`Save Culture: ${activeCulture?.culture}`}
        body={`Are you sure you want to save this culture?`}
        onClose={() => handleToggleReviewConfrimDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.ReadyForReview)}
        loading={editResult.isLoading}
      />

      <ConfirmDialog
        open={isRemoveDialogOpen}
        title={`Remove Culture: ${activeCulture?.culture}`}
        body={`Are you sure you want to remove this culture? This action is permanent and cannot be undone.`}
        onClose={() => setIsRemoveDialogOpen(false)}
        onConfirm={handleRemoveCulture}
        loading={deleteResult.isLoading}
      />

      <Box display="flex" justifyContent="space-between">
        <Tabs value={activeTabValue} onChange={handleTabChange}>
          {PageTabParams.map(value => {
            return (
              <Tab
                key={value.label}
                label={value.label}
                sx={{
                  p: 2,
                  mr: 2,
                }}
              />
            );
          })}
        </Tabs>
        {canCreateCulture && (
          <Box>
            <Button onClick={openCreateCultureDialog} sx={{ borderRadius: 0.5 }} color="primary" variant="outlined">
              CREATE CULTURE
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ width: "100%" }}>
        <CustomTabPanel value={activeTabValue} index={activeTabValue}>
          <Table
            columns={columns}
            data={data?.data ?? []}
            manualPagination
            onPaginationChange={setPagination}
            manualFiltering
            onGlobalFilterChange={setFilter}
            state={{ pagination }}
            rowCount={data?.total}
            loading={isLoading || isFetching}
            muiTableBodyRowProps={({ row }: { row: { id: string; index: number; original: IPreviewCulture } }) => ({
              onMouseEnter: () => {
                setHoveredRow(row.index);
                setActiveCulture(row.original);
              },
              onMouseLeave: () => {
                setHoveredRow(-1);
              },
              onClick: () => {
                handlePreviewCulture();
              },
            })}
          />
        </CustomTabPanel>
      </Box>
    </>
  );
};
