import { MenuItem } from "@mui/material";
import { ActionCell } from "../../components/ActionCell/ActionCell";
import { OneLiner } from "../../components/Table/Table";
import { userFriendlyDateTimestamp } from "../../utils";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { TabKeyEnum, TableMenuItemInterface, TagVisibilityEnum } from "../../utils/types";
import { UserPermissions } from "../../utils/permissions";
import { useHasPermission } from "../../utils/hooks";

interface Params {
  activeTab: TabKeyEnum;
  setHoveredRow: (rowIndex: number) => void;
  hoveredRow: number;
  handlePreviewTag: () => void;
  handleEditTag: () => void;
  handleUnpublishTag: () => void;
  handleRemoveTag: () => void;
  handlePublishTag: () => void;
}

export const useGetTagsTableColumns = (params: Params) => {
  const { setHoveredRow, hoveredRow, activeTab, handlePreviewTag, handleEditTag, handleRemoveTag, handleUnpublishTag, handlePublishTag } =
    params;

  const [shouldCloseMenuSelection, setShouldCloseMenuSelection] = useState<boolean>(false);

  const handlePreviewTagSelection = useCallback(() => {
    setShouldCloseMenuSelection(true);
    handlePreviewTag();
  }, [handlePreviewTag, setShouldCloseMenuSelection]);

  const handleEditSelectedTag = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleEditTag();
    },
    [handleEditTag, setShouldCloseMenuSelection],
  );

  const handleUnpublishSelectedTag = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleUnpublishTag();
    },
    [handleUnpublishTag],
  );

  const handleRemoveSelectedTag = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleRemoveTag();
    },
    [handleRemoveTag, setShouldCloseMenuSelection],
  );

  const handlePublishSelectedTag = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handlePublishTag();
    },
    [handlePublishTag],
  );

  const canEditReadyAndUnPublishedTags = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_TAGS);
  const canApprovePublishTags = useHasPermission(UserPermissions.APPROVE_PUBLISH_TAGS);
  const canRemoveTags = useHasPermission(UserPermissions.REMOVE_TAGS);
  const canUnpublishTags = useHasPermission(UserPermissions.UNPUBLISH_TAGS);

  const columns = useMemo(() => {
    const menuItems: TableMenuItemInterface[] = [
      {
        label: "Approve & Publish",
        onClick: handlePublishSelectedTag,
        visible: activeTab === TabKeyEnum.ReadyForReview && canApprovePublishTags,
      },
      {
        label: "Preview",
        onClick: handlePreviewTagSelection,
        visible: true,
      },
      {
        label: "Edit",
        onClick: handleEditSelectedTag,
        visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedTags,
      },
      {
        label: "Unpublish",
        onClick: handleUnpublishSelectedTag,
        visible: [TabKeyEnum.Published, TabKeyEnum.ReadyForReview].includes(activeTab) && canUnpublishTags,
      },
      {
        label: "Remove",
        onClick: handleRemoveSelectedTag,
        visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canRemoveTags,
        sx: { color: "red" },
      },
    ];

    return [
      { accessorKey: "tag", header: "Name", size: 200, Cell: ({ row }: any) => <OneLiner text={row.original.tag} /> },
      {
        accessorKey: "type",
        header: "Type",
        size: 200,
        Cell: ({ row }: any) => <OneLiner text={row.original.type} />,
      },
      {
        accessorKey: "mobileHidden",
        header: "Visibility",
        size: 200,
        Cell: ({ row }: any) => <OneLiner text={row.original.mobileHidden ? TagVisibilityEnum.Hidden : TagVisibilityEnum.Visible} />,
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ row }: any) => userFriendlyDateTimestamp(row.original.createdAt),
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        Cell: ({ row }: any) => <OneLiner text={row.original.createdBy} />,
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        Cell: ({ row }: any) => userFriendlyDateTimestamp(row.original.updatedAt),
      },
      {
        accessorKey: "updatedBy",
        header: "Updated By",
        Cell: ({ row }: any) => <OneLiner text={row.original.updatedBy} />,
      },
      {
        accessorKey: "action",
        id: "action",
        size: 50,
        header: "",
        enableResizing: false,
        muiTableHeadCellProps: () => ({
          sx: {
            visibility: "hidden",
          },
        }),
        Cell: ({ row }: any) => {
          return (
            <ActionCell
              shouldCloseMenuSelection={shouldCloseMenuSelection}
              setShouldCloseMenuSelection={setShouldCloseMenuSelection}
              hovered={hoveredRow === row.index}
              loading={false}
              activeTab={activeTab}
              onActionButtonClick={(event: MouseEvent<HTMLElement>) => {
                event?.stopPropagation?.();
                setHoveredRow(row.index);
              }}
            >
              {menuItems.map(
                (item, index) =>
                  item.visible && (
                    <MenuItem key={index} sx={item.sx} onClick={item.onClick}>
                      {item.label}
                    </MenuItem>
                  ),
              )}
            </ActionCell>
          );
        },
      },
    ];
  }, [
    handlePublishSelectedTag,
    activeTab,
    canApprovePublishTags,
    handlePreviewTagSelection,
    handleEditSelectedTag,
    canEditReadyAndUnPublishedTags,
    handleUnpublishSelectedTag,
    canUnpublishTags,
    handleRemoveSelectedTag,
    canRemoveTags,
    shouldCloseMenuSelection,
    hoveredRow,
    setHoveredRow,
  ]);

  return columns;
};
