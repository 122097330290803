import { userFriendlyDateTimestamp } from "../../utils";
import avatarPlacholder from "../../images/avatarPlacholder.png";
import { Avatar } from "../../components/Avatar/Avatar";
import { Book, TabKeyEnum, TableMenuItemInterface } from "../../utils/types";
import { Chip, MenuItem, Stack } from "@mui/material";
import { ActionCell } from "../../components/ActionCell/ActionCell";
import { MouseEvent, useMemo, useState, useCallback } from "react";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";

interface Params {
  activeTab: TabKeyEnum;
  setHoveredRow: (rowIndex: number) => void;
  hoveredRow: number;
  handlePreviewAuthor: () => void;
  handleEditAuthor: () => void;
  handleUnpublishAuthor: () => void;
  handlePublishAuthor: () => void;
  handleRemoveAuthor: () => void;
}
export const useGetAuthorsTableColumns = (params: Params) => {
  const {
    setHoveredRow,
    hoveredRow,
    activeTab,
    handlePreviewAuthor,
    handleEditAuthor,
    handleUnpublishAuthor,
    handlePublishAuthor,
    handleRemoveAuthor,
  } = params;

  const [shouldCloseMenuSelection, setShouldCloseMenuSelection] = useState<boolean>(false);

  const handlePreviewAuthorSelection = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handlePreviewAuthor();
    },
    [setShouldCloseMenuSelection, handlePreviewAuthor],
  );

  const handleEditSelectedAuthor = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleEditAuthor();
    },
    [handleEditAuthor],
  );

  const handleUnpublishSelectedAuthor = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleUnpublishAuthor();
    },
    [handleUnpublishAuthor],
  );

  const handlePublishSelectedAuthor = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handlePublishAuthor();
    },
    [handlePublishAuthor],
  );

  const handleRemoveSelectedAuthor = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleRemoveAuthor();
    },
    [handleRemoveAuthor],
  );

  const canEditReadyAndUnPublishedAuthors = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_AUTHORS);
  const canApprovePublishAuthors = useHasPermission(UserPermissions.APPROVE_PUBLISH_AUTHORS);
  const canRemoveAuthors = useHasPermission(UserPermissions.REMOVE_AUTHORS);
  const canUnpublishAuthors = useHasPermission(UserPermissions.UNPUBLISH_AUTHORS);

  const columns = useMemo(() => {
    const menuItems: TableMenuItemInterface[] = [
      {
        label: "Approve & Publish",
        onClick: handlePublishSelectedAuthor,
        visible: activeTab === TabKeyEnum.ReadyForReview && canApprovePublishAuthors,
      },
      {
        label: "Preview",
        onClick: handlePreviewAuthorSelection,
        visible: true,
      },
      {
        label: "Edit",
        onClick: handleEditSelectedAuthor,
        visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedAuthors,
      },
      {
        label: "Unpublish",
        onClick: handleUnpublishSelectedAuthor,
        visible: [TabKeyEnum.Published, TabKeyEnum.ReadyForReview].includes(activeTab) && canUnpublishAuthors,
      },
      {
        label: "Remove",
        onClick: handleRemoveSelectedAuthor,
        visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canRemoveAuthors,
        sx: { color: "red" },
      },
    ];

    return [
      { accessorKey: "id", header: "Author ID", size: 150 },
      {
        accessorKey: "avatar",
        header: "Avatar",
        size: 150,
        Cell: ({ row }: any) =>
          row.original.avatar ? (
            <img style={{ height: 50 }} src={row.original.avatar || avatarPlacholder} alt="avatar" />
          ) : (
            <Avatar name={row.original.name} />
          ),
      },
      { accessorKey: "name", header: "Author Name", size: 200 },
      {
        accessorKey: "books",
        header: "Books",
        size: 200,
        Cell: ({ row }: any) => (
          <Stack direction="row" spacing={1}>
            {row.original.books?.map((book: Book) => {
              return <Chip key={book.id} label={book.name} />;
            })}
          </Stack>
        ),
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ row }: any) => userFriendlyDateTimestamp(row.original.createdAt),
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        Cell: ({ row }: any) => row.original.createdBy?.name,
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        Cell: ({ row }: any) => userFriendlyDateTimestamp(row.original.updatedAt),
      },
      {
        accessorKey: "updatedBy",
        header: "Updated By",
        Cell: ({ row }: any) => row.original.updatedBy?.name,
      },
      {
        accessorKey: "action",
        id: "action",
        size: 50,
        header: "",
        enableResizing: false,
        muiTableHeadCellProps: () => ({
          sx: {
            visibility: "hidden",
          },
        }),
        Cell: ({ row }: any) => {
          if (hoveredRow !== row.index) return null;
          return (
            <ActionCell
              shouldCloseMenuSelection={shouldCloseMenuSelection}
              setShouldCloseMenuSelection={setShouldCloseMenuSelection}
              hovered={hoveredRow === row.index}
              loading={false}
              activeTab={activeTab}
              onActionButtonClick={(event: MouseEvent<HTMLElement>) => {
                event?.stopPropagation?.();
                setHoveredRow(row.index);
              }}
            >
              {menuItems.map(
                (item, index) =>
                  item.visible && (
                    <MenuItem key={index} sx={item.sx} onClick={item.onClick}>
                      {item.label}
                    </MenuItem>
                  ),
              )}
            </ActionCell>
          );
        },
      },
    ];
  }, [
    handlePublishSelectedAuthor,
    activeTab,
    canApprovePublishAuthors,
    handlePreviewAuthorSelection,
    handleEditSelectedAuthor,
    canEditReadyAndUnPublishedAuthors,
    handleUnpublishSelectedAuthor,
    canUnpublishAuthors,
    handleRemoveSelectedAuthor,
    canRemoveAuthors,
    hoveredRow,
    shouldCloseMenuSelection,
    setHoveredRow,
  ]);

  return columns;
};
