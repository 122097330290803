import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IpInfo } from "../../utils/types";

interface RegisterState {
  email: string;
  password: string;
}

export const LOGIN_ACTION = "LOGIN";
export const LOGOUT_ACTION = "LOGOUT";

export const login = async ({ email, password }: RegisterState, ipData: IpInfo) => {
  try {
    const customHeaders = {
      "x-user-ip": ipData.ip,
      "x-user-city": ipData.city,
      "x-user-country": ipData.country_name,
    };
    const { data } = await axios.post(
      `/api/auth/login`,
      {
        email,
        password,
      },
      {
        headers: customHeaders,
      },
    );
    return { data };
  } catch (error) {
    return { error };
  }
};

export const refresh = async () => {
  try {
    const { data } = await axios.post(`/api/auth/refresh`);
    return { data };
  } catch (error) {
    return { error };
  }
};

export const loginAction = (data: any) => ({
  type: LOGIN_ACTION,
  data,
});

export const useLogin = () => {
  const [formData, setFormData] = useState<RegisterState>({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<RegisterState>({
    email: "",
    password: "",
  });
  const [loading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrors({ email: "", password: "" });
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const navigate = useNavigate();
  const validate = (): boolean => {
    let valid = true;
    if (!formData.email) {
      valid = valid && false;
      setErrors(e => ({ ...e, email: "Email is required" }));
    }
    if (!formData.password) {
      valid = valid && false;
      setErrors(e => ({ ...e, password: "Password is required" }));
    }
    return valid;
  };
  const handleSubmit = async (ipData: IpInfo) => {
    if (!validate()) {
      return;
    }
    setIsLoading(true);
    const { error, data } = await login(formData, ipData);
    setIsLoading(false);
    if (error) {
      return setErrors(e => ({
        email: "Email or Password is incorrect",
        password: "Email or Password is incorrect",
      }));
    }
    dispatch(loginAction(data));
  };

  const refreshToken = async () => {
    setIsLoading(true);
    const { error, data } = await refresh();
    setIsLoading(false);
    if (error) {
      return null;
    }
    dispatch(loginAction(data));
    return true;
  };

  return {
    formData,
    handleChange,
    handleSubmit,
    navigate,
    errors,
    loading,
    refreshToken,
  };
};
