import { createApi } from "@reduxjs/toolkit/query/react";

import {
  CreateAuthorParams,
  CreateReferenceParams,
  CreateTagParams,
  EditAuthorParams,
  EditReferenceParams,
  EditTagParams,
  DeleteTagParams,
  GetAuthorsParams,
  GetReferencesParams,
  GetTagsParams,
  UserParams,
  UserUpdateParams,
  GetCulturesParams,
  CreateCultureParams,
  EditCultureParams,
  GetSourcesParams,
  EditSourceParams,
  CreateSourceParams,
  GetLanguagesParams,
  CreateLanguageParams,
  EditLanguageParams,
  GetQuoteTranslationsParams,
  CreateQuoteTranslationParams,
  EditQuoteTranslationParams,
  DeleteQuoteTranslationParams,
  UpdateProfileParams,
  GetUserLoginHistoryParams,
  GetQuotesParams,
  EditQuoteParams,
  CreateQuoteParams,
  GetTagQuotesParams,
  RegisterUserParams,
} from "./api.types";
import { axiosBaseQuery } from "./axiosBaseQuery";
import { getNameFromPath } from "../utils";

const baseUrl = "/api";

const providerTags = ["Users", "User", "Authors", "Tags", "References", "Cultures", "Sources", "Languages", "QueryTranslations", "Quotes"];

const defaultSortParams = {
  sortBy: "updatedAt",
  sortOrder: "DESC",
};

// Convert roles array param to a query param string
// e.g roles: ["Admin", "Creator"] => roles[]=Admin&roles[]=Creator
const rolesToQueryParam = (roles: string[]) => {
  return roles.map(role => `roles[]=${role}`).join("&");
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({ baseUrl }),
  tagTypes: providerTags,
  endpoints: builder => ({
    // Dummy endpoint to inavlidate all provider tags
    // TODO: Use login mutation to invalidate all provider tags
    invalidateAllProvideraTags: builder.mutation<any, void>({
      query: () => ({
        url: "/auth/refresh",
        method: "post",
      }),
      invalidatesTags: providerTags,
    }),

    // Auth
    // TODO: Currenly unused in the application
    login: builder.mutation<any, { email: string; password: string }>({
      query: body => ({
        url: "/auth/login",
        method: "post",
        data: body,
      }),
    }),

    // TODO: Currenly unused in the application
    refresh: builder.mutation<any, void>({
      query: () => ({
        url: "/auth/refresh",
        method: "post",
      }),
    }),

    getUser: builder.query<any, any>({
      query: params => ({
        url: "/auth/user",
        method: "get",
        params,
      }),
      providesTags: ["User"],
    }),

    registerUser: builder.mutation<any, RegisterUserParams>({
      query: body => ({
        url: "/auth/register",
        method: "post",
        data: body,
      }),
      // Do not invalidate any tags during registration because we are updating the same user to add roles.
      // Invalidation is handled in the updateUser mutation
      invalidatesTags: [],
    }),

    // Users
    getUsers: builder.query<any, UserParams>({
      query: ({ roles, ...otherParams }) => ({
        url: roles ? `/users?${rolesToQueryParam(roles)}` : "/users",
        method: "get",
        params: otherParams,
      }),
      providesTags: ["Users"],
    }),

    updateUser: builder.mutation<any, UserUpdateParams>({
      query: ({ id, ...otherParams }) => ({
        url: `/users/${id}`,
        method: "put",
        // Remove undefined or empty values from the object
        data: Object.fromEntries(Object.entries(otherParams).filter(([_, v]) => v != null && v !== "")),
      }),
      invalidatesTags: ["Users", "User"],
    }),

    getUserLoginHistory: builder.query<any, GetUserLoginHistoryParams>({
      query: ({ id, ...params }) => ({
        url: `/users/login-history?userId=${id}`,
        method: "get",
        params,
      }),
    }),

    // Profile
    updateProfile: builder.mutation<any, UpdateProfileParams>({
      query: ({ id, ...data }) => ({
        url: `/users/${id}/profile`,
        method: "put",
        data,
      }),
    }),

    // Quotes
    getQuotes: builder.query<any, GetQuotesParams>({
      query: params => ({
        url: "/quotes",
        method: "get",
        params: { ...defaultSortParams, ...params },
      }),
      providesTags: ["Quotes"],
    }),

    editQuote: builder.mutation<any, EditQuoteParams>({
      query: ({ id, ...data }) => ({
        url: `/quotes/${id}`,
        method: "put",
        data,
      }),
      invalidatesTags: ["Quotes"],
    }),

    createQuote: builder.mutation<any, CreateQuoteParams>({
      query: data => ({
        url: "/quotes",
        method: "post",
        data,
      }),
      invalidatesTags: ["Quotes"],
    }),

    deleteQuote: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        url: `/quotes/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Quotes"],
    }),

    // tags
    getTags: builder.query<any, GetTagsParams>({
      query: params => ({
        url: "/tags",
        method: "get",
        params,
      }),
      providesTags: ["Tags"],
    }),

    createTag: builder.mutation<any, CreateTagParams>({
      query: data => ({
        url: "/tags",
        method: "post",
        data,
      }),
      invalidatesTags: ["Tags"],
    }),

    editTag: builder.mutation<any, EditTagParams>({
      query: ({ id, ...otherData }) => ({
        url: `/tags/${id}`,
        method: "put",
        data: otherData,
      }),
      invalidatesTags: ["Tags"],
    }),

    deleteTag: builder.mutation<any, DeleteTagParams>({
      query: ({ id }) => ({
        url: `/tags/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Tags"],
    }),

    getTagQuotes: builder.query<any, GetTagQuotesParams>({
      query: ({ id, ...params }) => ({
        url: `/tags/${id}/quotes`,
        method: "get",
        params,
      }),
    }),

    // authors
    getAuthors: builder.query<any, GetAuthorsParams>({
      query: params => ({
        url: "/authors",
        method: "get",
        params,
      }),
      providesTags: ["Authors"],
    }),

    createAuthor: builder.mutation<any, CreateAuthorParams>({
      query: ({ name, published }) => ({
        url: "/authors",
        method: "post",
        data: { name, published },
      }),
      invalidatesTags: ["Authors"],
    }),

    editAuthor: builder.mutation<any, EditAuthorParams>({
      query: ({ name, id, published }) => ({
        url: `/authors/${id}`,
        method: "put",
        data: { name, published },
      }),
      invalidatesTags: ["Authors", "User"],
    }),

    deleteAuthor: builder.mutation<any, { id: number | string }>({
      query: ({ id }) => ({
        url: `/authors/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Authors"],
    }),

    // References
    getReferences: builder.query<any, GetReferencesParams>({
      query: params => ({
        url: "/refs",
        method: "get",
        params,
      }),
      providesTags: ["References"],
    }),

    createReference: builder.mutation<any, CreateReferenceParams>({
      query: ({ text, published }) => ({
        url: "/refs",
        method: "post",
        data: { text, published },
      }),
      invalidatesTags: ["References"],
    }),

    editReference: builder.mutation<any, EditReferenceParams>({
      query: ({ text, id, published }) => ({
        url: `/refs/${id}`,
        method: "put",
        data: { text, published },
      }),
      invalidatesTags: ["References"],
    }),

    deleteReference: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/refs/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["References"],
    }),

    // Cultures
    getCultures: builder.query<any, GetCulturesParams>({
      query: params => ({
        url: "/cultures",
        method: "get",
        params,
      }),
      providesTags: ["Cultures"],
    }),

    createCulture: builder.mutation<any, CreateCultureParams>({
      query: body => ({
        url: "/cultures",
        method: "post",
        data: body,
      }),
      invalidatesTags: ["Cultures"],
    }),

    // When updating a culture, the service expects only the file name of the icon, not the full path.
    // The fetch cultures data from the service returns the full path of the icon.
    // To ensure we send back only the file name when updating the culture, we extract the file name from the path.
    // This is handled globally here to maintain consistency across the application.
    editCulture: builder.mutation<any, EditCultureParams>({
      query: ({ id, icon, culture, published }) => ({
        url: `/cultures/${id}`,
        method: "put",
        data: { icon: getNameFromPath(icon), culture, published },
      }),
      invalidatesTags: ["Cultures"],
    }),

    deleteCulture: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/cultures/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Cultures"],
    }),

    // Sources
    getSources: builder.query<any, GetSourcesParams>({
      query: params => ({
        url: "/books",
        method: "get",
        params,
      }),
      providesTags: ["Sources"],
    }),

    createSource: builder.mutation<any, CreateSourceParams>({
      query: body => ({
        url: "/books",
        method: "post",
        data: body,
      }),
      invalidatesTags: ["Sources"],
    }),

    // When updating, the service expects only the file name of the icon, not the full path.
    // The fetch source data from the service returns the full path of the icon.
    // To ensure we send back only the file name when updating the source, we extract the file name from the path.
    // This is handled globally here to maintain consistency across the application.
    editSource: builder.mutation<any, EditSourceParams>({
      query: ({ id, name, isbn, authors, published, cover }) => ({
        url: `/books/${id}`,
        method: "put",
        data: { name, isbn, authors, published, cover: getNameFromPath(cover ?? "") },
      }),
      invalidatesTags: ["Sources"],
    }),

    deleteSource: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/books/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Sources"],
    }),

    // Languages
    getLanguages: builder.query<any, GetLanguagesParams>({
      query: params => ({
        url: "/langs",
        method: "get",
        params,
      }),
      providesTags: ["Languages"],
    }),

    createLanguage: builder.mutation<any, CreateLanguageParams>({
      query: ({ lang, published }) => ({
        url: "/langs",
        method: "post",
        data: { published, lang: lang.trim() },
      }),
      invalidatesTags: ["Languages"],
    }),

    editLanguage: builder.mutation<any, EditLanguageParams>({
      query: ({ id, lang, published }) => ({
        url: `/langs/${id}`,
        method: "put",
        data: { lang: lang.trim(), published },
      }),
      invalidatesTags: ["Languages"],
    }),

    deleteLanguage: builder.mutation<any, { id: string | number }>({
      query: ({ id }) => ({
        url: `/langs/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["Languages"],
    }),

    // Query Translations
    getQuoteTranslations: builder.query<any, GetQuoteTranslationsParams>({
      query: ({ quoteId }) => ({
        url: `/quotes/${quoteId}/trans`,
        method: "get",
      }),
      providesTags: ["QueryTranslations"],
    }),

    createQuoteTranslation: builder.mutation<any, CreateQuoteTranslationParams>({
      query: ({ quoteId, lang, text }) => ({
        url: `/quotes/${quoteId}/trans`,
        method: "post",
        data: { lang, text },
      }),
      invalidatesTags: ["QueryTranslations", "Quotes"],
    }),

    editQuoteTranslation: builder.mutation<any, EditQuoteTranslationParams>({
      query: ({ quoteId, id, ...otherParams }) => ({
        url: `/quotes/${quoteId}/trans/${id}`,
        method: "put",
        data: otherParams,
      }),
      invalidatesTags: ["QueryTranslations", "Quotes"],
    }),

    deleteQuoteTranslation: builder.mutation<any, DeleteQuoteTranslationParams>({
      query: ({ quoteId, id }) => ({
        url: `/quotes/${quoteId}/trans/${id}`,
        method: "delete",
      }),
      invalidatesTags: ["QueryTranslations", "Quotes"],
    }),
  }),
});

export const {
  // Invalidate all provider tags
  // TODO: Use the logout mutation to invalidate all provider tags
  useInvalidateAllProvideraTagsMutation,

  // Auth
  useLoginMutation,
  useRefreshMutation,
  useGetUserQuery,
  useRegisterUserMutation,

  // User
  useGetUsersQuery,
  useUpdateUserMutation,
  useUpdateProfileMutation,
  useGetUserLoginHistoryQuery,

  // Tags
  useGetTagsQuery,
  useCreateTagMutation,
  useEditTagMutation,
  useDeleteTagMutation,
  useGetTagQuotesQuery,

  // Authors
  useGetAuthorsQuery,
  useCreateAuthorMutation,
  useEditAuthorMutation,
  useDeleteAuthorMutation,

  // References
  useGetReferencesQuery,
  useCreateReferenceMutation,
  useEditReferenceMutation,
  useDeleteReferenceMutation,

  // Cultures
  useGetCulturesQuery,
  useCreateCultureMutation,
  useEditCultureMutation,
  useDeleteCultureMutation,

  // Sources
  useGetSourcesQuery,
  useCreateSourceMutation,
  useEditSourceMutation,
  useDeleteSourceMutation,

  // Languages
  useGetLanguagesQuery,
  useCreateLanguageMutation,
  useEditLanguageMutation,
  useDeleteLanguageMutation,

  // Query Translations
  useGetQuoteTranslationsQuery,
  useCreateQuoteTranslationMutation,
  useEditQuoteTranslationMutation,
  useDeleteQuoteTranslationMutation,

  // Quotes
  useGetQuotesQuery,
  useEditQuoteMutation,
  useCreateQuoteMutation,
  useDeleteQuoteMutation,
} = api;
