import reducer from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import { api } from "../api/apiSlice";
import { ipApi } from "../api/thirdPartyApiSlice";

const store = configureStore({
  reducer,
  // middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) => [...getDefaultMiddleware().prepend()],
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(ipApi.middleware),
} as any);

export default store;
