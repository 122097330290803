import { useRef, useState } from "react";
import { Box, Button, Tabs, Tab } from "@mui/material";
import { ConfirmDialog, CustomTabPanel, Table } from "../../components";
import { appendSearchParams, PageTabParams } from "../../utils";
import { IFileUploadHandle, IPreviewSource, ModalType, TabKeyEnum } from "../../utils/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteSourceMutation, useEditSourceMutation, useGetSourcesQuery } from "../../api/apiSlice";
import React from "react";
import { useGetSourcesTableColumns } from "./useGetSourcesTableColumns";
import toast from "react-hot-toast";
import { Drawer } from "../../components/Drawer/Drawer";
import { ViewSource } from "./ViewSource";
import { CreateOrEditSource } from "./CreateOrEditSource";
import { UserPermissions } from "../../utils/permissions";
import { useHasPermission } from "../../utils/hooks";

export const Sources = () => {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [urlParams] = React.useState(new URLSearchParams(location.search));
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [activeDrawer, setActiveDrawer] = useState<ModalType>("Create");
  const [activeSource, setActiveSource] = useState<IPreviewSource | null>(null);
  const [isUnpublishDialogOpen, setIsUnpublishDialogOpen] = React.useState(false);
  const [isPublishDialogOpen, setIsPublishDialogOpen] = React.useState(false);
  const [isReviewDialogOpen, setIsReviewDialogOpen] = React.useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filter, setFilter] = useState<string | undefined>(undefined);

  const [editSource, editResult] = useEditSourceMutation();
  const [deleteSource, deleteResult] = useDeleteSourceMutation();

  const fileUploadRef = useRef<IFileUploadHandle>(null);

  const activeTabParam = (urlParams.get("activeTab") ?? PageTabParams[0].value) as TabKeyEnum;
  const activeTabValue = Math.max(
    0,
    PageTabParams.findIndex(({ value }) => value === activeTabParam),
  );

  const apiSupportedParams = PageTabParams[activeTabValue].params;
  const { data, isLoading, isFetching } = useGetSourcesQuery({
    ...apiSupportedParams,
    name: filter || undefined,
    page: pagination.pageIndex + 1,
    per_page: pagination.pageSize,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const activeTab = PageTabParams[newValue].value;
    const newUrlParams = appendSearchParams({ activeTab }, urlParams);
    navigate(`${location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
  };

  const handleEditSource = () => {
    setActiveDrawer("Edit");
    setIsDrawerOpen(true);
  };

  const openCreateSourceDialog = () => {
    setHoveredRow(-1);
    setActiveSource(null);
    setActiveDrawer("Create");
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setActiveSource(null);
  };

  const handlePreviewSource = () => {
    setActiveDrawer("Preview");
    setIsDrawerOpen(true);
  };

  const columns = useGetSourcesTableColumns({
    activeTab: activeTabParam,
    setHoveredRow,
    hoveredRow,
    handlePreviewSource,
    handleEditSource,
    handleUnpublishSource: () => handleToggleUnpublishConfirmDialog(true),
    handlePublishSource: () => handleTogglePublishConfrimDialog(true),
    handleReviewSource: () => handleToggleReviewConfrimDialog(true),
    handleRemoveSource: () => handleToggleRemoveConfirmDialog(true),
  });

  const handleToggleUnpublishConfirmDialog = (open: boolean) => {
    setIsUnpublishDialogOpen(open);
  };

  const handleTogglePublishConfrimDialog = (open: boolean) => {
    setIsPublishDialogOpen(open);
  };

  const handleToggleReviewConfrimDialog = (open: boolean) => {
    setIsReviewDialogOpen(open);
  };

  const handleToggleRemoveConfirmDialog = (open: boolean) => {
    setIsRemoveDialogOpen(open);
  };

  const handleRemoveSource = () => {
    if (activeSource?.id) {
      deleteSource({ id: activeSource.id })
        .unwrap()
        .then(() => {
          handleToggleRemoveConfirmDialog(false);
          toast.success("Source removed successfully");
        })
        .catch(error => {
          toast.error(error?.data || "Failed to remove source");
        });
    } else {
      console.error("No source selected");
    }
  };

  const handlePublishChange = (published: TabKeyEnum) => {
    const messages = {
      [TabKeyEnum.Published]: {
        value: 1,
        success: "Source published successfully",
        error: "Failed to publish source",
      },
      [TabKeyEnum.ReadyForReview]: {
        value: -1,
        success: "Source saved successfully",
        error: "Failed to saved source",
      },
      [TabKeyEnum.Unpublished]: {
        value: 0,
        success: "Source unpublish successfully",
        error: "Failed to unpublish source",
      },
    };

    if (activeSource) {
      editSource({
        id: activeSource.id,
        name: activeSource.name,
        published: messages[published].value,
        authors: activeSource.authors,
      })
        .unwrap()
        .then(() => {
          handleToggleUnpublishConfirmDialog(false);
          handleTogglePublishConfrimDialog(false);
          handleToggleReviewConfrimDialog(false);
          handleDrawerClose();
          toast.success(messages[published].success);
        })
        .catch(error => {
          toast.error(error?.data || messages[published].error);
        });
    } else {
      console.error("No source selected");
    }
  };

  const canCreateSources = useHasPermission(UserPermissions.CREATE_SOURCES);

  return (
    <>
      <Drawer title={`${activeDrawer} Source`} onClose={handleDrawerClose} open={isDrawerOpen} sx={{ width: "500px", height: "100%" }}>
        {isDrawerOpen && (activeDrawer === "Create" || activeDrawer === "Edit") && (
          <CreateOrEditSource
            isModalOpen={true}
            handleClose={handleDrawerClose}
            modalType={activeDrawer}
            source={activeSource}
            fileUploadRef={fileUploadRef}
          />
        )}
        {isDrawerOpen && activeDrawer === "Preview" && (
          <ViewSource
            activeTab={activeTabParam}
            handleClose={handleDrawerClose}
            handleEditSource={handleEditSource}
            source={activeSource}
            handleReviewSource={() => handleToggleReviewConfrimDialog(true)}
            handlePublishSource={() => handleTogglePublishConfrimDialog(true)}
            handleUnpublishSource={() => handleToggleUnpublishConfirmDialog(true)}
          />
        )}
      </Drawer>
      <ConfirmDialog
        open={isUnpublishDialogOpen}
        title={`Unpublish Source: ${activeSource?.name}`}
        body={`Are you sure you want to unpublish this source?`}
        onClose={() => handleToggleUnpublishConfirmDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Unpublished)}
        loading={editResult.isLoading}
      />
      <ConfirmDialog
        open={isPublishDialogOpen}
        title={`Publish Source: ${activeSource?.name}`}
        body={`Are you sure you want to publish this source?`}
        onClose={() => handleTogglePublishConfrimDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Published)}
        loading={editResult.isLoading}
      />
      <ConfirmDialog
        open={isReviewDialogOpen}
        title={`Save Source: ${activeSource?.name}`}
        body={`Are you sure you want to save this source?`}
        onClose={() => handleToggleReviewConfrimDialog(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.ReadyForReview)}
        loading={editResult.isLoading}
      />
      <ConfirmDialog
        open={isRemoveDialogOpen}
        title={`Remove Source: ${activeSource?.name}`}
        body={`Are you sure you want to remove this source? This action is permanent and cannot be undone.`}
        onClose={() => handleToggleRemoveConfirmDialog(false)}
        onConfirm={handleRemoveSource}
        loading={deleteResult.isLoading}
      />
      <Box display="flex" justifyContent="space-between">
        <Tabs value={activeTabValue} onChange={handleTabChange}>
          {PageTabParams.map(value => {
            return (
              <Tab
                key={value.label}
                label={value.label}
                sx={{
                  p: 2,
                  mr: 2,
                }}
              />
            );
          })}
        </Tabs>
        {canCreateSources && (
          <Box>
            <Button onClick={openCreateSourceDialog} sx={{ borderRadius: 0.5 }} color="primary" variant="outlined">
              CREATE SOURCE
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ width: "100%" }}>
        <CustomTabPanel value={activeTabValue} index={activeTabValue}>
          <Table
            columns={columns}
            data={data?.data ?? []}
            manualPagination
            onPaginationChange={setPagination}
            manualFilter
            onGlobalFilterChange={setFilter}
            state={{ pagination }}
            rowCount={data?.total}
            loading={isLoading || isFetching}
            muiTableBodyRowProps={({ row }: { row: { id: string; index: number; original: IPreviewSource } }) => ({
              onMouseEnter: () => {
                setHoveredRow(row.index);
                setActiveSource(row.original);
              },
              onMouseLeave: () => {
                setHoveredRow(-1);
              },
              onClick: () => {
                handlePreviewSource();
              },
            })}
          />
        </CustomTabPanel>
      </Box>
    </>
  );
};
