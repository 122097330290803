import React from "react";
import { alpha, Box, Button, Chip, Divider, Typography } from "@mui/material";
import { IPreviewSource, TabKeyEnum, TabLabelEnum } from "../../utils/types";
import { userFriendlyDateTimestamp } from "../../utils";
import { TextView, TextViewProps } from "../../components/TextView/TextView";
import { UserPermissions } from "../../utils/permissions";
import { useHasPermission } from "../../utils/hooks";

interface Props {
  activeTab: TabKeyEnum;
  source: IPreviewSource | null;
  handleClose: () => void;
  handleEditSource: () => void;
  handlePublishSource: () => void;
  handleUnpublishSource: () => void;
  handleReviewSource: () => void;
}

interface TextViewInterface extends TextViewProps {
  visible?: boolean;
}

export const ViewSource: React.FC<Props> = props => {
  const { source, handleClose, handleEditSource, handlePublishSource, activeTab, handleReviewSource } = props;

  const canEditReadyAndUnPublishedSources = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_SOURCES);
  const canApprovePublishSources = useHasPermission(UserPermissions.APPROVE_PUBLISH_SOURCES);

  if (!source) {
    return (
      <Typography variant="h5" component="h2">
        No source selected
      </Typography>
    );
  }

  const editButtonVisible = [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedSources;
  const publishButtonVisible = activeTab === TabKeyEnum.ReadyForReview && canApprovePublishSources;
  const reviewButtonVisible = activeTab === TabKeyEnum.Unpublished && canEditReadyAndUnPublishedSources;

  const textViews: TextViewInterface[] = [
    { label: "Name", value: source.name },
    {
      label: "Published",
      value:
        source.published === 0 ? TabLabelEnum.Unpublished : source.published === -1 ? TabLabelEnum.ReadyForReview : TabLabelEnum.Published,
    },
    { label: "ISBN", value: source.isbn },
    {
      label: "Authors",
      value:
        source?.authors?.length > 0
          ? source.authors.map(authors => (
              <Chip sx={{ mr: 2, borderRadius: 1 }} variant="outlined" key={authors?.id} label={authors?.name} />
            ))
          : "No authors listed",
    },
    { label: "Created At", value: userFriendlyDateTimestamp(source.createdAt) },
    { label: "Created By", value: source.createdBy?.name },
    { label: "Updated At", value: userFriendlyDateTimestamp(source.updatedAt) },
    { label: "Updated By", value: source.updatedBy?.name },
    {
      label: "Cover",
      value: source.cover ? <img alt="icon" height={200} src={`/api${source.cover}`} loading="lazy" /> : null,
    },
  ];

  const alignButtonsEnd = !publishButtonVisible && !reviewButtonVisible && !editButtonVisible;

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: 400 }}>
      <Box>
        {textViews.map(textView =>
          textView.visible === undefined || textView.visible ? (
            <TextView key={textView.label} label={textView.label} value={textView.value} />
          ) : null,
        )}
      </Box>
      <Box sx={{ gap: 2 }} display="flex" flexDirection="column" justifyContent="space-between">
        <Divider />
        <Box
          display="flex"
          justifyContent={alignButtonsEnd ? "flex-end" : "space-between"} // When no buttons are visible, align to the right for cancel button
          sx={{ position: "relative", bottom: 0, gap: 3 }}
        >
          {publishButtonVisible && (
            <Button
              onClick={handlePublishSource}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Approve & Publish
            </Button>
          )}

          {reviewButtonVisible && (
            <Button
              onClick={handleReviewSource}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Ready for Review
            </Button>
          )}
          {editButtonVisible && (
            <Button
              onClick={handleEditSource}
              sx={{ flex: 1, borderRadius: "20px" }}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Edit
            </Button>
          )}
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
