import { Paper, Typography, Box, Button, CircularProgress, Autocomplete, TextField } from "@mui/material";
import { FC } from "react";
import { TextView } from "../../components/TextView/TextView";
import { ITranslation } from "../../utils/types";
import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";

type TranslationComponentProps = {
  translation: Partial<ITranslation>;
  currentTranslation: Partial<ITranslation> | null;
  languages: string[];
  isAddingTranslation: boolean;
  isEditingTranslation: Partial<ITranslation> | null;
  handleSaveTranslation: (data: Partial<ITranslation>) => void;
  handleEditTranslation: (data: Partial<ITranslation>) => void;
  handleOnChangeTranslation: (data: Partial<ITranslation>) => void;
  showTranslationComponentError: {
    translation: Partial<ITranslation>;
    error: string;
  } | null;
  handleCloseAddOrEditTranslation: () => void;
  handleRemoveTranslation: (data: Partial<ITranslation>) => void;
  isLoading: boolean;
  isLoadingDelete: boolean;
};

export const TEMPORARY_ID = "temporary-id";

export const TranslationComponent: FC<TranslationComponentProps> = props => {
  const {
    translation,
    languages,
    handleSaveTranslation,
    isEditingTranslation,
    currentTranslation,
    handleEditTranslation,
    showTranslationComponentError,
    handleCloseAddOrEditTranslation,
    handleOnChangeTranslation,
    handleRemoveTranslation,
    isLoading,
    isLoadingDelete,
  } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [errorLang, setErrorLang] = React.useState<string | null>(null);

  const handleSave = () => {
    if (currentTranslation?.lang && currentTranslation?.text) {
      handleSaveTranslation(currentTranslation);
      setError(null);
      setErrorLang(null);
      return;
    }

    if (!currentTranslation?.lang) {
      setErrorLang("Language is required");
    }

    if (!currentTranslation?.text?.trim()) {
      setError("Translation is required");
    }
  };

  const handleDelete = () => {
    if (translation.id) {
      handleRemoveTranslation(translation);
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleOnChangeTranslation({
      ...currentTranslation,
      text: event.target.value,
    });

    if (!event.target.value.trim()) {
      setError("Translation is required");
      return;
    }
    setError(null);
  };

  const handleLanguageChange = (value: string) => {
    handleOnChangeTranslation({
      ...currentTranslation,
      lang: value,
    });
    setErrorLang(null);
  };

  const canRemoveTranslations = useHasPermission(UserPermissions.REMOVE_TRANSLATIONS);

  // Using the same permission for creating and editing translations
  const canCreateTranslations = useHasPermission(UserPermissions.CREATE_TRANSLATIONS);

  const errors = [errorLang, error].filter(Boolean);

  const alignButtonEnd = !canCreateTranslations || !canRemoveTranslations;
  return (
    <>
      {isEditingTranslation?.id !== translation.id && translation.id !== TEMPORARY_ID && (
        <Paper square={false} elevation={8} sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: 600, mb: 1 }}>{translation.lang}</Typography>
          <TextView label={""} value={translation.text} />
          <Box display="flex" justifyContent={alignButtonEnd ? "flex-end" : "space-between"}>
            {canCreateTranslations && (
              <Button
                onClick={() => handleEditTranslation(translation)}
                disableElevation
                variant="outlined"
                sx={{ width: 100, borderRadius: "20px" }}
                color="primary"
                type="button"
              >
                Edit
              </Button>
            )}
            {canRemoveTranslations && (
              <Button
                onClick={() => handleDelete()}
                disableElevation
                variant="outlined"
                sx={{ width: 100, borderRadius: "20px" }}
                color="error"
                type="button"
              >
                {isLoadingDelete && <CircularProgress size={16} />} Delete
              </Button>
            )}
          </Box>
        </Paper>
      )}
      {(isEditingTranslation?.id === translation.id || translation.id === TEMPORARY_ID) && (
        <Paper square={false} elevation={8} sx={{ p: 2 }}>
          <Autocomplete
            id="lang"
            disableClearable
            options={languages}
            value={currentTranslation?.lang}
            isOptionEqualToValue={(option, value) => option?.toLowerCase() === value?.toLowerCase()}
            onChange={(_event, value) => handleLanguageChange(value)}
            sx={{ marginBottom: "16px" }}
            renderInput={params => <TextField {...params} label="Language" variant="standard" error={!!errorLang} helperText={errorLang} />}
          />
          <Box sx={{ marginBottom: "16px" }}>
            <TextField
              fullWidth
              id="text"
              label="Translation"
              multiline
              size="medium"
              value={currentTranslation?.text || ""}
              onChange={handleTextChange}
              onBlur={handleTextChange}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
          </Box>

          <Box display="flex" justifyContent={"space-between"}>
            <Button
              onClick={() => handleSave()}
              disabled={isLoading || errors.length > 0}
              disableElevation
              variant="outlined"
              sx={{ width: 100, borderRadius: "20px" }}
              color="primary"
              type="button"
            >
              {isLoading && <CircularProgress size={16} />} Save
            </Button>
            <Button
              disableElevation
              variant="outlined"
              sx={{ borderRadius: "20px" }}
              onClick={handleCloseAddOrEditTranslation}
              type="button"
            >
              <Typography sx={{ fontSize: "16px" }} color="textPrimary">
                Cancel
              </Typography>
            </Button>
          </Box>
          {showTranslationComponentError && showTranslationComponentError.translation.id === currentTranslation?.id && (
            <Typography sx={{ mt: 1 }} color="error">
              {showTranslationComponentError.error}
            </Typography>
          )}
        </Paper>
      )}
    </>
  );
};
