import { useGetUserQuery } from "../api/apiSlice";
import { canAccess, UserPermissions } from "./permissions";
import { UserRole } from "./types";

export const useHasPermission = (requiredPermission: UserPermissions) => {
  const { data } = useGetUserQuery({});
  const userRoles = data?.roles?.map((role: UserRole) => role.role) || [];

  return canAccess(userRoles, requiredPermission);
};

export const useHasAdminRole = () => {
  const { data, isLoading } = useGetUserQuery({});
  return { hasAdminRole: data?.roles?.some((role: UserRole) => role.role?.toLowerCase() === "admin"), isLoading };
};
